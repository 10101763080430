import { Directive, Injectable } from '@angular/core';
import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';
import { TUI_DATE_VALUE_TRANSFORMER } from '@taiga-ui/kit';

type From = TuiDay | null;

type To = string | null;

@Injectable()
export class StrawberryDateTransformer extends AbstractTuiValueTransformer<
  From,
  To
> {
  fromControlValue(controlValue: To): From {
    // eslint-disable-next-line prettier/prettier
    return controlValue && TuiDay.jsonParse(controlValue);
  }

  toControlValue(componentValue: From): To {
    return componentValue?.toString('YMD', '-') || null;
  }
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'tui-input-date[toStrawberryDate]',
  providers: [
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useClass: StrawberryDateTransformer,
    },
  ],
})
export class StrawberryDateDirective {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
