import { Directive, Injectable } from '@angular/core';
import { AbstractTuiValueTransformer, TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TUI_DATE_TIME_VALUE_TRANSFORMER } from '@taiga-ui/kit';

type From = [TuiDay | null, TuiTime | null];

type To = string | null;

@Injectable()
export class StrawberryDateTimeTransformer extends AbstractTuiValueTransformer<
  From,
  To
> {
  private readonly separator = `, `;
  fromControlValue(controlValue: To): From {
    const ndate = new Date(controlValue) || null;
    const day = (controlValue && TuiDay.fromLocalNativeDate(ndate)) || null;
    const time = (controlValue && TuiTime.fromLocalNativeDate(ndate)) || null;

    return [day, time];
  }

  toControlValue(componentValue: From): To {
    const [day, time] = componentValue;

    const resDate: Date | null = (day && day.toLocalNativeDate()) || null;
    if (resDate && time) {
      resDate.setHours(time.hours);
      resDate.setMinutes(time.minutes);
    }

    return resDate?.toISOString() || null;
  }
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'tui-input-date-time[toStrawberryDateTime]',
  providers: [
    {
      provide: TUI_DATE_TIME_VALUE_TRANSFORMER,
      useClass: StrawberryDateTimeTransformer,
    },
  ],
})
export class StrawberryDateTimeDirective {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
